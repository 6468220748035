.Connection_form_container {
    padding: 5px !important;
    margin-top: 25px !important;
}

.changeDate .DateRangePickerInput__withBorder {
    border: 1px solid #EAEDF6;
    border-radius: 8px;
    padding: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    /* Add other custom styles as needed */
}

.changeDate .DateRangePicker {
    width: 100%;
}

.changeDate .DateInput_input {
    text-align: center;
    color: #1C2B47;
}

.changeDate .DateInput {
    width: 100%;
}

.MuiDateRangeCalendar-root>div:first-child {
    color: transparent !important;
}

.dateranges .MuiOutlinedInput-input {
    padding: 11px 14px !important;
    font-size: 13px !important;
    font-weight: 400;
    font-family: 'Helvetica Neue';
    color: #1C2B47;
}

.dateranges .MuiInputLabel-root {
    top: -2px;
    font-size: 14px !important;
    font-weight: 400;
    font-family: 'Helvetica Neue';
    color: rgba(0, 0, 0, 0.3);
}

.start_endDate_coustom {
    border: none !important;
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 14px !important;
    font-family: "Helvetica Neue" !important;
    display: flex !important;
    align-items: center !important;
    background: #fff !important;
    /* padding: 6px 10px !important; */
}

.action_Connection {
    margin-right: 8px;
}

.action_Connection2 {
    margin-right: 4px;
}

.dateranges .MuiSvgIcon-root {
    display: none !important;
}


.MuiOutlinedInput-input.MuiSelect-select {
    height: 0 !important;
}

.MuiList-root-MuiMenu-list {
    height: 205px !important;
}

.dateranges .MuiOutlinedInput-root {
    border-radius: 8px !important;
}

.dateranges .MuiOutlinedInput-notchedOutline {
    border-color: #EAEDF6 !important;
}